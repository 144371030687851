<template>
    <div class="container-fluid">
        <div class="row">
            <div class="card">
                <div class="card-header d-flex justify-content-between">
                    <div class="header-title">
                        <h4 class="card-title">Novo cliente</h4>
                    </div>
                </div>
                <div class="card-body">
                    <div class="new-user-info">
                        <form @submit.prevent="onSubmit()">
                            <div class="row">
                                <div class="form-group col-md-6">
                                <label for="name">Nome:</label>
                                    <input 
                                        type="text" 
                                        v-model="name" 
                                        class="form-control"
                                        :class="`form-control ${$v.name.$error ? 'is-invalid' : ''}`" 
                                        id="name" 
                                        placeholder="Nome"
                                        @input="$v.name.$touch()" 
                                        @blur="$v.name.$touch()"
                                    >
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="vat">Contribuinte:</label>
                                    <input 
                                        type="text" 
                                        v-model="vat" 
                                        class="form-control"
                                        :class="`form-control ${$v.vat.$error ? 'is-invalid' : ''}`" 
                                        id="vat" 
                                        placeholder="Contribuinte"
                                        @input="$v.vat.$touch()" 
                                        @blur="$v.vat.$touch()"
                                    >
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="add1">Morada:</label>
                                    <input 
                                        type="text" 
                                        v-model="address" 
                                        class="form-control"
                                        :class="`form-control ${$v.address.$error ? 'is-invalid' : ''}`" 
                                        id="address" 
                                        placeholder="Morada"
                                        @input="$v.address.$touch()" 
                                        @blur="$v.address.$touch()"
                                    >
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="add2">Telemóvel:</label>
                                    <input 
                                        type="text" 
                                        v-model="phoneNumber" 
                                        class="form-control"
                                        :class="`form-control ${$v.phoneNumber.$error ? 'is-invalid' : ''}`" 
                                        id="phoneNumber" 
                                        placeholder="Telemóvel"
                                        @input="$v.phoneNumber.$touch()" 
                                        @blur="$v.phoneNumber.$touch()"
                                    >
                                </div>
                            </div>
                            <hr>
                            <button type="submit" class="btn btn-primary">Adicionar</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
     </div>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required, integer } from 'vuelidate/lib/validators'
export default {
   name:'UserAdd',
   mixins: [validationMixin],
   validations: {
        name: { required },
        vat: { required, integer },
        address:{ required  },
        phoneNumber:{ required }
    },
    data() {
        return {
            name: '',
            vat: '',
            address: '',
            phoneNumber: ''
        }
    },
    methods:{
        onSubmit()
        {
            let clients = JSON.parse(localStorage.getItem('clients'));
            this.$v.$touch()
            if (!this.$v.$invalid) {
                clients.push({Nome: this.name, Contribuinte: this.vat, Morada: this.address, Telemóvel: this.phoneNumber})
                localStorage.setItem('clients', JSON.stringify(clients));
                this.$router.push({name:'app.clients-list'})
            }
        }
    }
}
</script>